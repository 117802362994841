import { getAllStatus } from '@/actions/comercial/metas'
import { isTheRolEnabled } from '@/helpers/featureFlagHelpers'
import useGetUserByRol from '@/hooks/requests/useGetUserByRol'
import { TypeModal } from '@/interfaces/Interface'
import { TYPES_CHART } from '@/interfaces/config'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import PerformanceCard from './PerformanceCard'
import { usePerformanceChartContext } from './context'
import ModalGoalReport from './modal-goal-report'
import { SkeletonChart } from './skeleton-chart'
import './styles/index.css'

export default function PerformanceCharts({
    userSelected,
    selectedUserSyncHistory
}: {
    userSelected: string | null | undefined,
    selectedUserSyncHistory: any
}) {
    const {
        handleChangeAgentsSelected,
        isLoadingAnalyticChart,
        periodInformation,
        periodSelected,
        analyticChart,
        periodDate,
        agentsId,
        chartType,
        goals
    } = usePerformanceChartContext()
    const [isOpenModalInfo, setOpenModalInfo] = useState<boolean | TypeModal>(false)
    const handleOpenModalInformation = (type: TypeModal) => setOpenModalInfo(type)


    const isSuperUser = isTheRolEnabled(['super', 'comercial'])

    const getListStatus = useQuery({
        queryKey: ["all-list-status"],
        queryFn: () => getAllStatus(),
        enabled: !!isOpenModalInfo && isSuperUser
    })

    const { users } = useGetUserByRol({ roles: ["user"], enabled: isSuperUser })

    useEffect(() => {
        handleChangeAgentsSelected(userSelected)
    }, [userSelected])

    const chartsOptions = useMemo(() => {
        const { contacts, contactsValue, sale, salePlus, saleValue, tracing, tracingValue, } = periodInformation
        return periodInformation
            ? [
                {
                    title: "Contactos",
                    code: "contacts",
                    action: () => handleOpenModalInformation("contacts"),
                    goalExpected: contacts,
                    value: contactsValue,
                    goalsAchieved: _.get(analyticChart, "contacts", {}),
                },
                {
                    title: "Seguimientos",
                    code: "tracing",
                    action: () => handleOpenModalInformation("tracing"),
                    goalExpected: tracing,
                    value: tracingValue,
                    selectedUser: selectedUserSyncHistory,
                    goalsAchieved: _.get(analyticChart, "tracing", {}),
                },
                {
                    title: "Ventas",
                    code: "sale",
                    action: () => handleOpenModalInformation("sale"),
                    goalExpected: sale,
                    value: saleValue,
                    goalPlus: salePlus,
                    goalsAchieved: _.get(analyticChart, "sale", {}),
                },
            ]
            : []
    }, [JSON.stringify(periodInformation), agentsId, userSelected, { ...analyticChart }])

    return (
        <>
            {!periodInformation.emptyGenerated && isOpenModalInfo && !!goals[0] && (
                <ModalGoalReport
                    close={() => setOpenModalInfo(false)}
                    status={getListStatus.data ?? []}
                    periodSelected={periodSelected}
                    open={isOpenModalInfo}
                    firstGoal={goals[0]}
                    user={userSelected}
                    usersIds={agentsId}
                    sellers={users}
                />
            )}
            <ContainerChart chartType={chartType}>
                {
                    chartsOptions.map(({ action, value, ...rest }, inx) => {
                        if (isLoadingAnalyticChart) return <SkeletonChart />
                        return <PerformanceCard
                            generated={periodInformation.emptyGenerated}
                            activeLine={inx < chartsOptions.length - 1}
                            periodSelected={periodSelected}
                            startAndEndGoal={periodDate}
                            onClick={() => action()}
                            chartType={chartType}
                            user={userSelected}
                            goalValue={value}
                            {...rest}
                        />
                    }
                    )}
            </ContainerChart>
        </>
    )
}

export function ContainerChart({ chartType, children }) {
    if (chartType === TYPES_CHART['DETAILED']) {
        return <div className='container-chart-detailed min-h-[230px]'>
            {children}
        </div>
    }

    return <div className="container-chart-simplified min-h-[230px]">
        {children}
    </div>
}