import { getListUserByGoal } from "@/actions/comercial/metas";
import useDebounce from "@/components/core/functions/useDebouce";
import SearchForm from "@/components/core/SearchForm";
import { PaginationMode, ReusableTable } from "@/components/Table/ReusableTable";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { isTheRolEnabled, ROLES } from "@/helpers/featureFlagHelpers";
import { useQuery } from "@tanstack/react-query";
import { CheckIcon, ChevronRight } from "lucide-react";
import moment from "moment";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dataFilters } from "../dataFilters";
import { tableColums } from "./libs/columns";
import { dataGoal, filtersEmptyValue, LIMIT_ELEMENTS } from "./libs/constants";

export const ModalGoalReport = ({
    open,
    close,
    firstGoal,
    sellers,
    periodSelected,
    user,
    usersIds,
    status
}) => {
    const [filters, setFilters] = useState<any>({
        ...filtersEmptyValue,
        users: [...usersIds],
        date: [`${moment(firstGoal?.start).toISOString()}|${moment(firstGoal?.end).toISOString()}`]
    })

    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [countDocument, setCountDocument] = useState<number>(0)
    const [page, setPage] = useState<number>(1)

    const router = useRouter()
    const keyword = useDebounce(keywordSearch, 500)

    const fetchDetailGoal = useCallback(async (props) => {
        const response = await getListUserByGoal({ ...props })
        return response.json()
    }, [user, open, keyword, filters])

    const goalResult = useQuery({
        queryKey: ['users-by-goals', page, keyword, usersIds, open, filters],
        enabled: !!open,
        queryFn: () => fetchDetailGoal({
            filters, limit: LIMIT_ELEMENTS, page: page, search: (keyword as string).trim().split(' ').join('+'), type: open,
        })
    })

    const tableColumns = useMemo(() => !open ? [] : tableColums[open] || [], [open])
    const tableData = useMemo(() => goalResult.data?.[open]?.documents || [], [open, tableColums, goalResult])

    const optionsFilters = useMemo(() => {
        return dataFilters({
            goal: firstGoal, periodSelected, sellers: sellers ? sellers?.map(seller => (
                {
                    label: `${seller.name} ${seller.lastname}`,
                    data: seller._id
                }
            )) : [], status: status ?? []
        })
    }, [open, sellers])

    useEffect(() => {
        const newCountDocument = goalResult?.data && goalResult.data?.[open]?.countDocument
        if (newCountDocument === 0) {
            return setCountDocument(0)
        }
        if (countDocument === 0 || (goalResult.data && newCountDocument !== countDocument)) {
            return setCountDocument(newCountDocument)
        }
    }, [goalResult.data])

    useEffect(() => {
        setFilters({
            ...filtersEmptyValue,
            date: [`${moment(firstGoal.start).toISOString()}|${moment(firstGoal.end).toISOString()}`],
            users: [...usersIds]
        })
        setKeywordSearch('')
        setPage(1)
    }, [open, usersIds, firstGoal])

    const handleNavigateToReport = () => router.push(dataGoal[open].href)

    const handleSelectedFilter = (code, filter, isMultiple = true) => {
        let allFilterCopy = { ...filters }
        const findFilter = filters[code].indexOf(filter)

        if (isMultiple) {
            if (findFilter !== -1) {
                allFilterCopy?.[code]?.splice(findFilter, 1)
            } else {
                allFilterCopy[code] = [...allFilterCopy?.[code], filter]
            }
        } else {
            allFilterCopy[code] = [filter]
        }

        setFilters(allFilterCopy)
    }

    return <Dialog open={!!open} onOpenChange={(value) => close(!value)}>
        <DialogContent className="sm:max-w-[1100px] flex flex-col h-[92vh]">
            <DialogHeader>
                <DialogTitle>{dataGoal[open].title}</DialogTitle>
            </DialogHeader>
            <div className="relative flex flex-col flex-grow">
                <div className="w-full flex flex-wrap items-center gap-2">
                    <SearchForm
                        handleChange={(value) => setKeywordSearch(value)}
                        placeholder="Buscar..."
                        value={keywordSearch}
                    />

                    {optionsFilters.map(data => {
                        const activeFilters = filters[data.code] ?? [];
                        const filterLabels = activeFilters.map(filter => {
                            const filterData = data.options.find(item => item.data === filter);
                            return filterData?.label;
                        }).filter(Boolean).join(',');
                        const isFilterActive = !!filterLabels;

                        return dataGoal[open].filterActives.includes(data.code) && <>
                            {
                                (data.code === 'sellers' || data.code === 'agents')
                                    && !isTheRolEnabled([ROLES.SUPER, ROLES.COMERCIAL])
                                    ? null
                                    : <Popover key={data.code}>
                                        <PopoverTrigger>
                                            <Button variant="outline" className={`border border-input hover:text-neutral-800 hover:bg-slate-50  border-dashed flex-[2] capitalize flex text-[12px] ${isFilterActive ? 'text-primary hover:text-primary' : ''} items-center gap-1`}>
                                                {
                                                    isFilterActive ? filterLabels.length > 20 ? filterLabels.slice(0, 20).concat('...') : filterLabels : <span className="flex items-center gap-1">
                                                        {data.icon}
                                                        {data.label}
                                                    </span>
                                                }
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="p-0" align="start">
                                            <Command>
                                                <CommandInput placeholder='Search...' />
                                                <CommandList className="h-max relative flex flex-col">
                                                    <CommandEmpty>Sin resultados</CommandEmpty>
                                                    <CommandGroup>
                                                        {
                                                            data.options.map(item => {
                                                                const isAgent = data.code === 'agents' || data.code === 'sellers'
                                                                const isSelected = filters?.[isAgent ? 'users' : data.code].find(filterItem => filterItem === item.data)
                                                                return <CommandItem
                                                                    onSelect={() => handleSelectedFilter(isAgent ? 'users' : data.code, item.data, data.isMultiple)}
                                                                    key={item.label}>
                                                                    <div
                                                                        className={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary ${isSelected
                                                                            ? "bg-primary text-primary-foreground"
                                                                            : "opacity-50 [&_svg]:invisible"}`}
                                                                    >
                                                                        <CheckIcon className={"h-4 w-4"} />
                                                                    </div>
                                                                    <span>{item.label}</span>
                                                                </CommandItem>
                                                            })
                                                        }
                                                    </CommandGroup>
                                                </CommandList>
                                            </Command>
                                        </PopoverContent>
                                    </Popover>
                            }
                        </>
                    })}
                    <Button onClick={handleNavigateToReport} variant={'ghost'} className="flex text-primary hover:text-primary items-center text-[12px] gap-1 border-dashed border-input border">
                        Ver reporte completo
                        <ChevronRight size={15} />
                    </Button>
                </div>
                <div className='w-full flex-grow top-0 overflow-hidden mb-[5px] box-border relative mt-2' key={open}>
                    <div className='absolute w-full overflow-auto flex h-full'>
                        <ReusableTable
                            paginationMode={PaginationMode.Server}
                            isLoading={goalResult.isLoading}
                            rowCount={countDocument}
                            pageSize={LIMIT_ELEMENTS}
                            activePagination={true}
                            onPageChange={setPage}
                            page={page}
                            dataTable={{
                                data: tableData,
                                columns: tableColumns
                            }}
                        />
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>
};

export default ModalGoalReport;
